@font-face {
  font-family: '#{$vrf-font-family}';
  src:  url('#{$vrf-font-path}/#{$vrf-font-family}.eot?6byigi');
  src:  url('#{$vrf-font-path}/#{$vrf-font-family}.eot?6byigi#iefix') format('embedded-opentype'),
    url('#{$vrf-font-path}/#{$vrf-font-family}.ttf?6byigi') format('truetype'),
    url('#{$vrf-font-path}/#{$vrf-font-family}.woff?6byigi') format('woff'),
    url('#{$vrf-font-path}/#{$vrf-font-family}.svg?6byigi##{$vrf-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="vrf-icon-"], [class*=" vrf-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$vrf-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vrf-icon-buildings {
  &:before {
    content: $vrf-icon-buildings;
  }
}
.vrf-icon-agriculture {
  &:before {
    content: $vrf-icon-agriculture;
  }
}
.vrf-icon-vehicles {
  &:before {
    content: $vrf-icon-vehicles;
  }
}
